export const urls = {
    HOME_WIDGET_URL: '/',
    PARCEL_WIDGET_URL : '/packages',
    BATCH_WIDGET_URL: '/batches',
    PARCEL_TRACKING_WIDGET_URL : '/parcel-tracking',
    DRIVER_PROFILE_WIDGET_URL: '/fleet-dashboard/drivers',
    VEHICLE_PROFILE_WIDGET_URL : '/fleet-dashboard/vehicles',
    DRIVER_ROSTER_WIDGET_URL : '/fleet-dashboard/daily-roster',
    EDIT_DRIVER_WIDGET_URL : '/fleet-dashboard/edit-driver',
    ADD_VEHICLE_WIDGET_URL: '/fleet-dashboard/add-vehicle',
    LIVE_TRACKING_WIDGET_URL : '/live-tracking',
    RETURN_TERMINAL_WIDGET_URl : '/returns-terminal',
    MANIFEST_UPLOAD_WIDGET_URL : '/manifest',
    TOTES_WIDGET_URL: '/assets-management/totes',
    WAREHOUSE_OPERATION_WIDGET_URL : '/assets-management/warehouse-operations',
    ROUTES_WIDGET_URL : '/system-routes',
    INDUCTION_WIDGET_URL: '/induction',
    USER_MANAGEMENT_WIDGET_URL : '/user-account',
    CLIENT_MANAGEMENT_WIDGET_URL : '/client-management',
    ADD_USER_WIDGET_URL : '/add-user',
    EDIT_USER_WIDGET_URL : '/edit-user',
    EDIT_CLIENT_WIDGET_URL : '/client-management/edit-client',
    PROFILE_WIDGET_URL: '/update-profile',
    REMEDIATION_WIDGET_URL: '/remediation',
    REMEDIATION_PARCEL_WIDGET_URL : '/remediation/parcel',
    REMEDIATION_SCHEDULE_JOBS_WIDGET_URL : '/remediation/schedule-jobs',
    FLEET_DASHBOARD_URL : '/fleet-dashboard',
    ASSET_MANAGEMENT_URL : '/assets-management',
    RETURN_TO_SENDER_WIDGET_URL : '/returnToSender',
    LOGIN_URL : '/login',
    REGISTER_URL : '/register',
    GENERATE_QR_CODE_URL : '/generateQRCode',
    RESET_PASSWORD_URL : '/reset-password',
    ERROR_PAGE_URL : '/error',
    REPORTING_WIDGET_URL: '/reporting-dashboard',
    CLIENT_REPORTING_WIDGET_URL: '/client-reporting-dashboard',
    ROUTE_OPERATION_WIDGET_URL: '/data-management/route-operation',
    FSA_ZONE_WIDGET_URL: '/data-management/fsa-zones',
    FSA_SUB_ZONE_WIDGET_URL: '/data-management/fsa-subzones',
    DATA_MANAGEMENT_URL : '/data-management',
    ORDERS_WIDGET_URL : '/orders',
    OPERATIONS_WIDGET_URL : '/operations',
    ADMIN_WIDGET_URL : '/admin',
    FORGET_PASSWORD_URL : '/forget-password',
}
