import { environment } from "../environments/environment.jsx";
import api from './api';
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class UserSerice {
    loggedInUser(formData) {
        const endpoint = `${apiEndpoint}/api/authenticate`;
        formData['fcmToken'] = localStorage?.getItem('fcmToken')
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }
    getLoggedInUser() {
        const endpoint = `${apiEndpoint}/api/account`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
    registerUser(formData) {
        const endpoint = `${apiEndpoint}/api/register`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }
    gererateQRCode(formData) {
        const endpoint = `${apiEndpoint}/api/2FA/qrrecovery?login=${formData}`;
        return api.get(endpoint);
    }
    resetPassword(formData) {
        const endpoint = `${apiEndpoint}/api/admin/users/changepassword`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }
    fetchUserData(userName) {
        const endpoint = `${apiEndpoint}/public/api/verify/userid/${userName}`;
        return api.get(endpoint)
    }
    forgotPassword(formData) {
        const {type, ...rest} = formData
        const endpoint = `${apiEndpoint}/public/api/resetpassword?Authentication-Type=${type}`;
        return api.put(endpoint, rest, {
            headers: getHeader(),
        });
    }
    gererateOTP(formData) {
        const endpoint = `${apiEndpoint}/public/api/forgetpassword/genrateotp`;
        return api.post(endpoint, formData);
    }
}