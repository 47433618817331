import React, { useState } from "react";
import './Input.scss';
import { useFormContext } from 'react-hook-form';
import Alert from '@mui/material/Alert'
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const InputField = ({ name, label,  formErrors, type = 'text', required=true, ...rest }) => {
    const { register, formState: { errors } } = useFormContext();
    const error = formErrors ? formErrors.find(error=> error.field === name) : null
    const [showPassword, setShowPassword] = useState(false);
    
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

   return (
    <div className="inputFieldContainer">
      {label && <label data-testid={`label-${name}`} >{label}</label>}
      <div className="inputWrapper">
      <input
        name={name}
        type={type === 'password' && !showPassword ? 'password' : 'text'}
        {...register(name, { required })}
        {...rest}
        className='inputField'
        data-testid={`name-${name}`}
      />
       {type === 'password' && (
         <button
         type="button"
         className="togglePassword"
         onClick={handleTogglePasswordVisibility}
         aria-label="toggle password visibility">
         {showPassword ? <VisibilityOff /> : <Visibility/>}
         </button>
        )}
      </div>
      {errors?.[name] && <span><Alert severity="error">This field is required!</Alert><br/></span>}
      {error && <span><Alert severity="error">{error?.message}</Alert><br/></span>}
    </div>
  );
};
