import { urls } from 'utils/urls'

export const transformedErrors = (errorsObject) => {
    if(errorsObject){
        return Object.keys(errorsObject)?.map(key => ({
            field: key,
            message: errorsObject[key]
        }));
    }
}

export const resetLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginName');
}

export const PUBLIC_URL = [urls?.LOGIN_URL, urls?.REGISTER_URL, urls?.ERROR_PAGE_URL, urls?.GENERATE_QR_CODE_URL, urls?.FORGET_PASSWORD_URL]
