import { createSlice } from '@reduxjs/toolkit';
import { login, fetchLoggedInUserDetails, register, fetchUserProfile, logout, gererateQRCode, resetPassword, fetchUserData, forgotPassword, generateOTP } from './authAction'

const initialState = {
  token: null,
  loading: false,
  error: null,
  user: null,
  isFetchUserLoading: false,
  userFetchError: null,
  isRegisterUserLoading: false,
  userRegisterError: null,
  isRegistered: false,
  userProfile: null,
  qrCodeData: null,
  passwordExpiryMessage: null,
  success: false,
  tempUserData: null,
  otpSuccess: null
}
// Redux slice for auth
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.passwordExpiryMessage = null
    },
    logoutSuccess: (state) => {
      return initialState; // Reset state to initial values
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoginLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoginLoading = false;
        state.user = null; //Remove previously stored user details
        state.token = action?.payload?.id_token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoginLoading = false;
        if (action?.payload?.title) {
          state.error = action.payload?.detail || action.payload?.title;
        }
        else {
          state.passwordExpiryMessage = action?.payload?.message
          state.token = action?.payload?.id_token;

        }
      })

      //Fetch User data
      .addCase(fetchLoggedInUserDetails.pending, (state) => {
        state.isFetchUserLoading = true;
        state.userFetchError = null;
      })
      .addCase(fetchLoggedInUserDetails.fulfilled, (state, action) => {
        state.isFetchUserLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchLoggedInUserDetails.rejected, (state, action) => {
        state.isFetchUserLoading = false;
        state.userFetchError = action.payload;
      })

      //Register user
      .addCase(register.pending, (state) => {
        state.isRegisterUserLoading = true;
        state.userRegisterError = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isRegisterUserLoading = false;
        state.isRegistered = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isRegisterUserLoading = false;
        state.userRegisterError = action.payload;
      })

      // Fetch User Profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.userProfile = action?.payload
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      // Logout
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage?.removeItem('token');
        // Dispatch the logoutSuccess action
        return logoutSuccess();
      })

      //Generate QR Code
      .addCase(gererateQRCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(gererateQRCode.fulfilled, (state, action) => {
        state.loading = false;
        state.qrCodeData = action?.payload;
      })
      .addCase(gererateQRCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      //resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      //Forgot password
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.tempUserData = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //forgot password
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      //forgot password
      .addCase(generateOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateOTP.fulfilled, (state) => {
        state.loading = false;
        state.otpSuccess = 'OTP is sent to your registred email';
      })
      .addCase(generateOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

  }
});

export const { logoutSuccess, resetError } = authSlice.actions;
export default authSlice.reducer;
