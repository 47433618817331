/* Created nested list here, as menus are divided in different submenus
Note - can not directly import component, below approach is ued to avoid circular dependency
*/

import { EditUser } from 'component/userManagement/userAccount/EditUser'
import { UserStepperForm } from 'component/userManagement/userAccount/UserStepperForm'
import { UserAccount } from 'component/userManagement/userAccount/UserAccount'
import { Driver } from 'component/userManagement/driver/Driver'
import { Manifest } from 'component/userManagement/manifest/Manifest'
import { Packages } from 'component/packages/Packages'
import { Remediation } from 'component/packages/Remediation'
import { LiveTracking } from 'component/liveTracking/liveTracking'
import { Routes } from 'component/route/route'
import { DailyRoaster } from 'component/dailyRoaster/DailyRoaster'
import UpdatePackage from 'component/packages/updatePackage'
import { Batches } from 'component/batches/batches'
import { EditDriver } from 'component/userManagement/driver/EditDriver'
import { ReturnTerminal } from 'component/returnDashboard/ReturnTerminal'
import { WarehouseOperation } from 'component/warehouseOperations/WarehouseOperations'
// import { RollerCage } from 'component/warehouseOperations/RollerCage'
import { Totes } from 'component/warehouseOperations/Totes'
import { Vehicles } from 'component/vehicles/Vehicles'
import { AddVehicle } from 'component/vehicles/AddVehicle'
import { EditClient } from 'component/userManagement/client/EditClient'
import { Client } from 'component/userManagement/client/Client'
import { ProfileUpdateForm } from 'component/userManagement/userAccount/ProfileUpdateForm'
import { DailyDashboard } from 'component/dashboard/DailyDashboard'
import { ReturnToSender } from 'component/packages/ReturnToSender'
import { RouteOperation } from 'component/routeOperations/RouteOperation'
import ReportingDashboard from 'component/reporting/Reporting'
import { ScheduleJobs } from 'component/scheduleJobs/ScheduleJobs'
import ClientReportingDashboard from 'component/reporting/ClientReporting'
import { FSAZone } from 'component/fsaZone/FSAZone'
import { FSASubZone } from 'component/fsaZone/FSASubZone'
import InductionWidgetIcon from '@mui/icons-material/AssignmentTurnedIn';

import HomeIcon from 'icons/HomeIcon'
import BatchIcon from 'icons/BatchesIcon'
import SearchProductIcon from 'icons/SearchProductIcon'
import DriverDashboardIcon from 'icons/DriverDashboardIcon'
import FindLocationIcon from 'icons/FindLocationIcon'
import ReturnProductIcon from 'icons/ReturnProductIcon'
import HomeDeliveryIcon from 'icons/HomeDeliveryIcon'
import ManagementIcon from 'icons/ManagementIcon'
import RoutingIcon from 'icons/RoutingIcon'
import ProfessionalSkillsIcon from 'icons/ProfessionalSkillsIcon'
import AssetsManagementIcon from 'icons/AssetsManagementIcon'
import WarehouseOperationIcon from 'icons/WarehouseOperation'
import BoxesIcon from 'icons/BoxesIcon'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StorageIcon from '@mui/icons-material/Storage';
import PublicIcon from '@mui/icons-material/Public';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

import { urls } from 'utils/urls'
import { Induction } from 'component/route/induction'
import { FullHeightMap } from 'component/route/FullHeightMap'

// Define menu options as properties of an object
const menuOptions = {
    HOME_WIDGET: {
        title: 'HOME',
        icon: <HomeIcon />,
        path: urls?.HOME_WIDGET_URL,
        component: <DailyDashboard />,
        show: true
    },
    PARCEL_WIDGET: {
        title: 'PARCELS',
        icon: <Inventory2OutlinedIcon />,
        show: true,
        path: urls?.PARCEL_WIDGET_URL,
        component: <Packages />,
    },
    BATCH_WIDGET: {
        title: 'BATCHES',
        icon: <BatchIcon />,
        show: true,
        path: urls?.BATCH_WIDGET_URL,
        component: <Batches />,
    },
    PARCEL_TRACKING_WIDGET: {
        title: 'PARCEL TRACKING',
        icon: <SearchProductIcon />,
        show: true,
        path: urls?.PARCEL_TRACKING_WIDGET_URL,
        component: <UpdatePackage />,
    },
    DRIVER_PROFILE_WIDGET: {
        title: 'DRIVER PROFILES',
        component: <Driver />,
        path: urls?.DRIVER_PROFILE_WIDGET_URL,
        icon: <ProfessionalSkillsIcon />,
    },
    VEHICLE_PROFILE_WIDGET: {
        title: 'VEHICLE PROFILES',
        component: <Vehicles />,
        icon: <DirectionsCarOutlinedIcon sx={{ height: 20, width: 20 }} />,
        path: urls?.VEHICLE_PROFILE_WIDGET_URL
    },
    DRIVER_ROSTER_WIDGET: {
        title: 'DRIVER ROSTER',
        icon: <HomeDeliveryIcon />,
        path: urls?.DRIVER_ROSTER_WIDGET_URL,
        component: <DailyRoaster />,
    },
    EDIT_DRIVER_WIDGET: {
        title: 'Edit Driver',
        path: urls?.EDIT_DRIVER_WIDGET_URL,
        component: <EditDriver />,
        show: false
    },
    ADD_VEHICLE_WIDGET: {
        title: 'Add Vehicle',
        path: urls?.ADD_VEHICLE_WIDGET_URL,
        component: <AddVehicle />,
        show: false
    },
    LIVE_TRACKING_WIDGET: {
        title: 'LIVE TRACKING',
        icon: <FindLocationIcon />,
        show: true,
        path: urls?.LIVE_TRACKING_WIDGET_URL,
        component: <LiveTracking />
    },
    RETURN_TERMINAL_WIDGET: {
        title: 'RETURNS TERMINAL',
        icon: <ReturnProductIcon />,
        show: true,
        component: <ReturnTerminal />,
        path: urls?.RETURN_TERMINAL_WIDGET_URl
    },
    MANIFEST_UPLOAD_WIDGET: {
        title: 'MANIFEST UPLOAD',
        icon: <ManagementIcon />,
        show: true,
        path: urls?.MANIFEST_UPLOAD_WIDGET_URL,
        component: <Manifest />
    },
    TOTES_WIDGET: {
        title: 'TOTES',
        component: <Totes />,
        path: urls?.TOTES_WIDGET_URL,
        icon: <BoxesIcon />,
    },
    WAREHOUSE_OPERATION_WIDGET: {
        title: 'INDUCTION FLOOR PLAN',
        component: <WarehouseOperation />,
        icon: <WarehouseOperationIcon />,
        path: urls?.WAREHOUSE_OPERATION_WIDGET_URL
    },
    ROUTES_WIDGET: {
        title: 'ROUTING',
        icon: <RoutingIcon />,
        show: true,
        path: urls?.ROUTES_WIDGET_URL,
        component: <Routes />
    },
    INDUCTION_WIDGET: {
        title: 'INDUCTION PROGRESS',
        icon: <InductionWidgetIcon  />,
        show: true,
        path: urls?.INDUCTION_WIDGET_URL,
        component: <Induction />
    },
    USER_MANAGEMENT_WIDGET: {
        title: 'USER MANAGEMENT',
        icon: <ProfessionalSkillsIcon />,
        path: urls?.USER_MANAGEMENT_WIDGET_URL,
        component: <UserAccount />,
        show: true
    },
    CLIENT_MANAGEMENT_WIDGET: {
        title: 'CLIENT MANAGEMENT',
        icon: <GroupIcon />,
        path: urls?.CLIENT_MANAGEMENT_WIDGET_URL,
        component: <Client />,
        show: true
    },
    ADD_USER_WIDGET: {
        title: 'Add User',
        path: urls?.ADD_USER_WIDGET_URL,
        component: <UserStepperForm />,
        show: false
    },
    EDIT_USER_WIDGET: {
        title: 'Edit User',
        path: urls?.EDIT_USER_WIDGET_URL,
        component: <EditUser />,
        show: false
    },
    EDIT_CLIENT_WIDGET: {
        title: 'Edit Client',
        path: urls?.EDIT_CLIENT_WIDGET_URL,
        component: <EditClient />,
        show: false
    },
    PROFILE_WIDGET: {
        title: 'PROFILE',
        icon: <AccountCircleIcon />,
        show: true,
        component: <ProfileUpdateForm />,
        path: urls?.PROFILE_WIDGET_URL,
    },
    REMEDIATION_PARCEL_WIDGET: {
        title: 'REMEDIATION',
        icon: <StoreMallDirectoryIcon/>,
        show: true,
        path: urls?.REMEDIATION_PARCEL_WIDGET_URL,
        component: <Remediation />,
    },
    SCHEDULE_JOBS: {
        title: 'SCHEDULE JOBS',
        icon: <WorkHistoryIcon />,
        show: true,
        path: urls?.REMEDIATION_SCHEDULE_JOBS_WIDGET_URL,
        component: <ScheduleJobs />,
    },
    RETURN_TO_SENDER_WIDGET: {
        title: 'RETURN TO SENDER',
        icon: <AssignmentReturnIcon />,
        show: true,
        path: urls?.RETURN_TO_SENDER_WIDGET_URL,
        component: <ReturnToSender />,
    },
    ROUTE_OPERATION_WIDGET: {
        title: 'EXECUTIVE CONTROL',
        icon: <AdminPanelSettingsIcon />,
        show: true,
        path: urls?.ROUTE_OPERATION_WIDGET_URL,
        component: <RouteOperation />,
    },
    REPORTING_WIDGET: {
        title: 'REPORTING DASHBOARD',
        icon: <AnalyticsIcon />,
        show: true,
        path: urls?.REPORTING_WIDGET_URL,
        component: <ReportingDashboard />,
    },
    CLIENT_REPORTING_WIDGET: {
        title: 'REPORTING DASHBOARD',
        icon: <AnalyticsIcon />,
        show: true,
        path: urls?.CLIENT_REPORTING_WIDGET_URL,
        component: <ClientReportingDashboard />,
    },
    FSA_ZONE_WIDGET: {
        title: 'FSA ZONES',
        icon: <PublicIcon />,
        show: true,
        path: urls?.FSA_ZONE_WIDGET_URL,
        component: <FSAZone />,
    },
    FSA_SUB_ZONE_WIDGET: {
        title: 'FSA SUB ZONES',
        icon: <PinDropIcon/>,
        show: true,
        path: urls?.FSA_SUB_ZONE_WIDGET_URL,
        component: <FSASubZone />,
    },
    //New one
    FULL_HEIGHT_MAP: {
        title: 'SEQUENCE',
        show: true,
        path: '/sequence',
        component: <FullHeightMap />,
    },
}

export const sideMenuOptions = {
    ROLE_ADMIN: [
        [menuOptions?.HOME_WIDGET,menuOptions?.PARCEL_TRACKING_WIDGET,

        {
            title : 'ORDERS',
            icon: <InventoryOutlinedIcon/>,
            show: true,
            path: urls?.ORDERS_WIDGET_URL,
            children:[
                menuOptions?.PARCEL_WIDGET,
                menuOptions?.MANIFEST_UPLOAD_WIDGET
            ]
        },
        {
            title : 'OPERATIONS',
            icon: <StoreMallDirectoryIcon/>,
            show: true,
            path: urls?.OPERATIONS_WIDGET_URL,
            children:[
                menuOptions?.LIVE_TRACKING_WIDGET,
                menuOptions?.BATCH_WIDGET,
                menuOptions?.ROUTES_WIDGET,
                menuOptions?.INDUCTION_WIDGET,
                menuOptions?.RETURN_TERMINAL_WIDGET,
                menuOptions?.WAREHOUSE_OPERATION_WIDGET,
                menuOptions?.TOTES_WIDGET
            ] 
        },
        menuOptions?.RETURN_TO_SENDER_WIDGET, menuOptions?.REMEDIATION_PARCEL_WIDGET,
        {
            title: 'FLEET',
            icon: <DriverDashboardIcon />,
            show: true,
            path: urls?.FLEET_DASHBOARD_URL,
            children: [
                menuOptions?.DRIVER_PROFILE_WIDGET,
                menuOptions?.VEHICLE_PROFILE_WIDGET,
                menuOptions?.DRIVER_ROSTER_WIDGET,
            ],
        },
        menuOptions?.EDIT_DRIVER_WIDGET,
        menuOptions?.ADD_VEHICLE_WIDGET,
        ],
        [menuOptions?.REPORTING_WIDGET],

        [
            {
                title: 'ADMIN',
                icon: <DriverDashboardIcon />,
                show: true,
                path: urls?.ADMIN_WIDGET_URL,
                children: [
                    menuOptions?.USER_MANAGEMENT_WIDGET,
                    menuOptions?.CLIENT_MANAGEMENT_WIDGET,
                    menuOptions?.ROUTE_OPERATION_WIDGET,
                    menuOptions?.FSA_ZONE_WIDGET,
                    menuOptions?.FSA_SUB_ZONE_WIDGET,
                    menuOptions?.SCHEDULE_JOBS
                ],
            },
                    menuOptions?.ADD_USER_WIDGET,
                    menuOptions?.EDIT_USER_WIDGET,
                    menuOptions?.EDIT_CLIENT_WIDGET,
                    menuOptions?.FULL_HEIGHT_MAP
        ]
    ],
    ROLE_DRIVER: [],
    ROLE_PFSC: [],
    ROLE_RSC: [],
    ROLE_CUSTOMER: [
        [
            menuOptions?.HOME_WIDGET,
            {
            title : 'ORDERS',
            icon: <InventoryOutlinedIcon/>,
            show: true,
            path: urls?.ORDERS_WIDGET_URL,
            children:[
                menuOptions?.PARCEL_WIDGET,
                menuOptions?.MANIFEST_UPLOAD_WIDGET
            ]
        },
            menuOptions?.PARCEL_TRACKING_WIDGET,
            menuOptions?.PROFILE_WIDGET,
            menuOptions?.CLIENT_REPORTING_WIDGET
        ]
    ],
    ROLE_CSR: [
        [
            menuOptions?.HOME_WIDGET,
            menuOptions?.PARCEL_WIDGET,
            menuOptions?.PARCEL_TRACKING_WIDGET,
            menuOptions?.REMEDIATION_PARCEL_WIDGET,
            menuOptions?.PROFILE_WIDGET
        ]
    ],
    ROLE_SUPERVISOR: [
        [menuOptions?.HOME_WIDGET,menuOptions?.PARCEL_TRACKING_WIDGET,

    {
        title : 'ORDERS',
        icon: <InventoryOutlinedIcon/>,
        show: true,
        path: urls?.ORDERS_WIDGET_URL,
        children:[
            menuOptions?.PARCEL_WIDGET,
            menuOptions?.MANIFEST_UPLOAD_WIDGET
        ]
    },
    {
        title : 'OPERATIONS',
        icon: <StoreMallDirectoryIcon/>,
        show: true,
        path: urls?.OPERATIONS_WIDGET_URL,
        children:[
            menuOptions?.LIVE_TRACKING_WIDGET,
            menuOptions?.BATCH_WIDGET,
            menuOptions?.ROUTES_WIDGET,
            menuOptions?.INDUCTION_WIDGET,
            menuOptions?.RETURN_TERMINAL_WIDGET,
            menuOptions?.WAREHOUSE_OPERATION_WIDGET,
            menuOptions?.TOTES_WIDGET
        ] 
    },
    menuOptions?.RETURN_TO_SENDER_WIDGET, menuOptions?.REMEDIATION_PARCEL_WIDGET,
    {
        title: 'FLEET',
        icon: <DriverDashboardIcon />,
        show: true,
        path: urls?.FLEET_DASHBOARD_URL,
        children: [
            menuOptions?.DRIVER_PROFILE_WIDGET,
            menuOptions?.VEHICLE_PROFILE_WIDGET,
            menuOptions?.DRIVER_ROSTER_WIDGET,
        ],
    },
    menuOptions?.EDIT_DRIVER_WIDGET,
    menuOptions?.ADD_VEHICLE_WIDGET,
    ],
    ]
}

